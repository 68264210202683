<template>
  <div>
    <EventScriptParagraph image-id="guides/navigation_qr" :float-right="true" :scale="false">
      <template v-slot:imageCaption>
        <div class="text-muted small">Google Navigation</div>
      </template>
      <h2>Anfahrt</h2>
      <p>
        Am einfachsten ist die Anfahrt mit dem Auto. Ihr könnt auf den
      <div class="d-block mx-3 my-2 text-center font-weight-bolder">
        <a href="https://www.google.com/maps/place/SPABoot+-+Saunaboot+Verleih/@51.2266616,12.4490052,17z/data=!3m1!4b1!4m5!3m4!1s0x47a6fd5f67a1bb21:0x851fabf0d3c8d2a4!8m2!3d51.2266616!4d12.4511939"
           alt="SPABoot Saunabootverleih in Google Maps">
          Parkplatz des Lagovida Hotels, Hafenstraße 1, Großpösna
        </a>
      </div>
      <p>
        fahren und dort auch parken.
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph image-id="guides/lounge_2">
      <h2>Check-in</h2>
      <p>
        Alle Teilnehmer treffen sich vorab in der SPABoot-Lounge.
        Diese
        befindet sich rechter Hand auf dem Parkplatz des Lagovida-Hotels.
      </p>
      <p>
        Los geht es dort mit einem kleinen Willkommensgetränk. Jetzt werden auch die Aufgüsse des SPABoot Aufguss-Menüs
        „verkostet“ und ausgewählt. Sobald alle Teilnehmer versammelt sind, gehen wir gemeinsam zum Boot im Hafen.
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph image-id="guides/dressing">
      <h2>Willkommen an Bord</h2>
      <p v-if="!trip">
        Nach einer kurzen Sicherheitseinweisung und der Bekanntmachung mit den Eigenschaften des Boots, kann das
        Schwitzen beginnen.
      </p>
      <p v-else>
        Da es mindestens 2 Personen an Bord geben muss, die das Boot auch steuern können, erfolgt nun eine Einweisung
        in das Führen und manövrieren des Bootes auf dem See, den damit verbundenen Verhaltens-, Umwelt- und
        Verkehrsregeln
        und den entsprechenden Sicherheitsvorkehrungen.<br/>
        Eine ausführliche Broschüre mit allen Informationen für die "KapitänInnen" sowie die "Passagiere" liegt immer
        griffbereit während der ganzen Zeit bereit.<br/>
        Zu den Verhaltensregeln findet sich ein separater Anhang an unsere
        <router-link :to="{name: 'termsAndConditions'}">Allgemeinen Geschäftsbedingungen</router-link>
      </p>
      <p>
        Die Sauna ist bereits vorgeheizt, Deine ausgesuchten Aufgüsse stehen bereit und für ausreichend Brennholz ist
        gesorgt. Es kann los gehen!
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph image-id="guides/sauna">
      <h2>Saunieren & Entspannen</h2>
      <p v-if="trip">
        Erkundet den See, um einen geeigneten ruhigen Ort für das Saunieren, zu finden, an dem Ihr ungestört seid.
        Nachdem Ihr das Boot verankert habt, kann das Saunieren beginnen.
      </p>
      <p>
        Während der Sauna genießt ihr den Ausblick aus den Panoramafenstern und könnt euch, nach dem Saunagang, mit
        einem Sprung in den See abkühlen.<br/>
        Wasser und Tee stehen für Euch kostenlos zur Verfügung.<br/>
        Macht es Euch in der Sauna-Lounge zu Eurer Lieblingsmusik gemütlich oder genießt die Ruhe und den Seeblick auf
        der großen Relax-Dachterrasse.
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph image-id="guides/wenik_"
                          v-if="wenik">
      <h2>WENIK-Ritual</h2>
      <p>
        Ein authentisch zelebriertes „Wenik-Ritual“ ist ein Sauna-Erlebnis der ganz besonderen Art.<br/>
        Nach dem ersten Saunagang, der klassisch mit einem Schluck Wodka begonnen wird, folgt im zweiten Durchgang die
        traditionelle Wenik-Massage: ein Abschlagen des Körpers mit frischen Birkenzweigen, welches die Blutzirkulation
        anregt und durch die ätherischen Öle der Birkenblätter ein wohliges Gefühl der Entspannung hinterlässt.
        In den Ruhepausen zwischen den Sauna-Gängen gönnt Ihr Euch Snacks und Tee.<br/>
        Das spezielle SPABoot-Peeling im dritten Saunagang reinigt und pflegt die Haut und schließt das Ritual ab.
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph v-if="footbath">
      <h2>Wärmendes Fußbad</h2>
      <p>
        Ein warmes Fußbad nach der Sauna kann Wunder wirken. Unsere speziellen Fußbadmischungen, lassen jeden Fuß
        wohlig warm werden und wirken entspannend auf die Gelenke.<br/>
        An Board sind Schüsseln für das Fußbad bereitgestellt. Das heiße Wasser (Vorsicht: sehr heiß!) entnehmt Ihr
        dem Hahn am Saunaofen.<br/>
        Für das Fußbad bietet sich ein schöner Platz achtern an oder auch die Dachterrasse.
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph image-id="guides/sunset_steg">
      <h2>Check-out</h2>
      <p>
        Leider geht auch das schönste Erlebnis irgendwann zu Ende. Das SPABoot-Team kümmert sich auch jetzt noch um Euch
        und kommt Euch pünktlich am Boot wieder abholen.
      </p>
    </EventScriptParagraph>

    <EventScriptParagraph>
      <p>
        Solltet Ihr Getränke konsumiert haben, die nicht inkludiert waren, so rechnen wir diese jetzt ab.<br/>
        Danach habt Ihr die Gelegenheit, die sanitäre Infrastruktur (Duschen und WC) des Lagovida Hotels nebst
        Umkleidekabinen zu nutzen, um Euch frisch für den restlichen Tag oder Abend zu machen.<br/>
      </p>
      <p>
        Solltet Ihr Euer Erlebnis vor Ablauf der Zeit beenden wollen, so meldet Euch bitte beim SPABoot-Team. Ihr
        könnt jederzeit telefonisch Kontakt mit uns aufnehmen: {{ $store.getters.configuration.phone }}.<br/>
        Bitte lauft nicht eigenmächtig im Hafengelände umher, sondern wartet, bis wir Euch abholen!<br/>
      </p>
    </EventScriptParagraph>
  </div>
</template>

<script>
import EventScriptParagraph from "@/components/products/EventScriptParagraph";

const tripProducts = ['RES-0001', 'RES-0002', 'RES-0003', 'RES-0004', 'RES-0010']

export default {
  components: {EventScriptParagraph},
  props: {
    product: Object
  },
  data() {
    return {
      facets: null
    }
  },
  computed: {
    arrivalBufferInMinutes() {
      return this.trip ? 30 : 15
    },
    trip() {
      return tripProducts.indexOf(this.product?.id) >= 0
    },
    wenik() {
      return ['RES-0004', 'RES-0006'].indexOf(this.product?.id) >= 0
    },
    footbath(){
      return ['RES-0004', 'RES-0006', 'RES-0009', 'RES-0010'].indexOf(this.product?.id) >= 0
    },
    peeling(){
      return this.footbath()
    }
  }
}
</script>
<style scoped>
.row {
  margin-top: 1.9rem;
}
</style>
