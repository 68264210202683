<template>
  <div class="row mt-4 mt-md-0">
    <div v-if="imageId"
         class="col-12 order-0 col-md-3 my-auto text-center"
         :class="{'order-md-3': floatRight}">
      <div :class="{'d-inline-flex': !scale}">
        <cld-image :public-id="imageId"
                   :class="{'img-fluid': scale}"
                   fetch="auto"
                   responsive="true"
                   crop="scale"
                   :height="height"
                   :width="width"/>
      </div>
      <slot name="imageCaption"/>
    </div>
    <div
        class="col-12 order-1 mt-4 mt-md-0"
        :class="{'col-md-9': imageId}">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    floatRight: {
      type: Boolean,
      default: false
    },
    imageId: String,
    scale: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 100
    }
  },
  computed: {
    width() {
      return this.scale ? 'auto' : this.height
    }
  }
}
</script>
