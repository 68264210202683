<template>
  <StandardLayout v-if="product">
    <template v-slot:main>
      <div class="heading text-center mt-3">
        <cld-image :public-id="product.imageId"
                   class="img-fluid"
                   width="auto"
                   height="150"
                   fetch="auto"
                   crop="scale">
          <cld-transformation quality="auto:low"/>
        </cld-image>
        <h1 class="mt-4 mt-md-0">{{ product.name }}</h1>
        <div>{{ price | price }} für {{ product.duration }} Stunden</div>
        <div>Teilnehmer: {{ product.minAttendees }}-{{ product.maxAttendees }}</div>
        <div class="text-muted small">{{ product.attendees }} Teilnehmer im Basispreis enthalten</div>
      </div>
      <nav class="mt-4">
        <ul class="nav nav-tabs">
          <router-link :to="{name: 'productDetail', params: {productId: product.id}, hash: '#inklusiv'}" v-slot="{href}"
                       custom>
            <li class="nav-item link"
                @click="currentTab = 'components'">
              <a :href="href" class="nav-link" :class="{active: currentTab === 'components'}">
                Inklusivleistungen
              </a>
            </li>
          </router-link>
          <router-link :to="{name: 'productDetail', params: {productId: product.id}, hash: '#ablauf'}" v-slot="{href}"
                       custom>
            <li class="nav-item">
              <a :href="href" class="nav-link" :class="{active: currentTab === 'script'}">
                Ablauf
              </a>
            </li>
          </router-link>
        </ul>
      </nav>
      <div class="container bg-white p-3 pt-4 tab">
        <EventScript :product="product"
                     v-show="currentTab === 'script'"/>
        <div v-show="currentTab === 'components'">
          <ProductDetail :product="product"/>
        </div>
      </div>
      <div class="text-center mt-3 sticky-bottom">
        <router-link :to="{name: returnRouteName}" class="btn btn-primary font-weight-bolder">
          Zurück
        </router-link>
      </div>
    </template>
  </StandardLayout>
</template>

<script>
import EventScript from "@/components/products/EventScript";
import StandardLayout from "@/layouts/StandardLayout";
import ProductDetail from "@/components/products/ProductDetail";

export default {
  components: {ProductDetail, EventScript, StandardLayout},
  props: {
    productId: String,
    returnRouteName: {
      type: String,
      default: 'productsAndPrices'
    }
  },
  data() {
    return {
      product: null,
      price: 0,
      currentTab: 'components'
    }
  },
  watch: {
    productId: {
      handler(newProductId) {
        if (newProductId)
          this.updateProduct()
      },
      immediate: true
    },
    $route: {
      handler() {
        if (this.$route.hash === '#ablauf')
          this.currentTab = 'script'
        else {
          this.currentTab = 'components'
        }
      },
      immediate: true
    }
  },
  methods: {
    async updateProduct() {
      this.product = await this.$api.get(`/products/${this.productId}`)
          .then(res => res.data)
          .catch(err => this.handleError(err))
      this.price = await this.$api.get('/prices', {
        params: {
          product: this.product.id,
          attendees: this.product.attendees
        }
      }).then(response => response.data.amount)
          .catch(error => this.handleError(error))
    }
  }
}
</script>

<style scoped>
.heading {
  position: relative;
  min-height: 20%;
  max-height: 20%;
}

.tab {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.sticky-bottom {
  position: sticky;
  bottom: 40px;
}

</style>
