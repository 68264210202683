<template>
  <div v-html="detailHtml"></div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  data() {
    return {
      detailHtml: ''
    }
  },
  watch: {
    product: {
      handler(newProduct) {
        if (newProduct) {
          this.$api.get(`/product-details/${newProduct.detailId}`)
              .then(res => this.detailHtml = res.data.content)
              .catch(err => this.handleError(err))
        }
      },
      immediate: true
    }
  }
}
</script>
